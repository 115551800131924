import React, {useEffect, useContext} from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { GreenButton } from '../../components/components/Buttons'
import RisingSpans from '../../components/components/RisingSpans'
import {motion, MotionConfig, useAnimation, usePresence} from 'framer-motion'
import {expertiseHolder } from '../pages.module.scss'
import {SectionNavContext} from '../../components/utilities/SiteContexts'

const sunVariants = {
  pre: {
    pathLength: 0
  },
  vis: {
    pathLength: 1,
    transition:{
      duration:1
    }
  },
  post: {
    pathLength: 0,
    transition:{
      duration:1
    }
  }
}

const lineVariants = {
  pre: {
    pathLength: 0
  },
  vis: {
    pathLength: 1,
    transition:{
      duration:1
    }
  },
  post: {
    pathLength: 0
  }
}

const lhsVariants = {
  pre: {},
  vis: {
    transition: {
      staggerChildren:0.1
    }
  },
  post: {
    transition: {
      staggerChildren:0.1
    }
  }
}

const rhsVariants = {
  pre: {
    opacity:0,
    x:'20rem'
  },
  vis: {
    opacity:1,
    x:0,
    transition: {
      duration:1
    }
  },
  post: {
    opacity:0,
    x:'20rem'
  }
}

const liVariants = {
  pre: {
    opacity:0,
    x:'-2rem'
  },
  vis: {
    opacity:1,
    x:0,
    transition: {
      staggerChildren:0.5
    }
  },
  post: {
    opacity:0,
    x:'2rem'
  }
}

const tickSVGVariants = {
  pre: {
  },
  vis: {
    transition: {
      staggerChildren:1
    }
  },
  post: {

  }
}


const tickVariants = {
  pre: {
    pathLength: 0
  },
  vis: {
    pathLength: 1,
    transition:{
      duration:0.5
    }
  },
  post: {
    pathLength: 0
  }
}



const Tick = () => {

  return (
    <motion.svg variants={tickSVGVariants} className="tick" width="27" height="25" viewBox="0 0 27 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <motion.path variants={tickVariants} d="M23.1899 11.9979C23.5666 18.2133 18.8334 23.5572 12.618 23.9338C6.40258 24.3105 1.05866 19.5773 0.682004 13.3619C0.305344 7.14648 5.03857 1.80257 11.254 1.42591C14.7145 1.21619 17 2.35864 19.5 4.35864" stroke="currentColor"/>
      <motion.path variants={tickVariants} d="M6.89008 11.1672L11.3406 16.4641L25.5481 4.69468" stroke="#44A572" strokeWidth="3"/>
    </motion.svg>

  )
}





export default function ExpertisePage() {

  const rhsControl = useAnimation()
  const lhsControl = useAnimation()
  const headerControl = useAnimation()
  const sunControl = useAnimation()
  const lineControl = useAnimation()

  const [isPresent, safeToRemove] = usePresence()


  const [, setSectionNav] = useContext(SectionNavContext)


    const introAnimation = async () => {
      sunControl.start('vis')
      rhsControl.start('vis')
      await headerControl.start('vis')
      lhsControl.start('vis')
      lineControl.start('vis')
  }



  useEffect(() => {
    introAnimation()
    setSectionNav([
      {
        to: '/',
        title: 'Home'
      },
      {
        to: '/about/video',
        title: 'Video'
      },
      { 
        to: '/about/benefits',
        title: 'Benefits'
      },
      {
        to: '/about/expertise',
        title: 'Expertise',
        active:true
      },
      {
        to: '/about/achievements',
        title: 'Achievements'
      },
      {
        to: '/about/contact',
        title: 'Contact'
      }
    ])
  }, [])

  useEffect(() => {
    const exitAnimation = async () => {
      lineControl.start('post')
      lhsControl.start('post')
      headerControl.start('post')
      rhsControl.start('post')
      await sunControl.start('post')
      safeToRemove()
    }

    if(!isPresent) {
      exitAnimation()
    }
  }, [isPresent, lineControl, lhsControl, headerControl, rhsControl, sunControl, safeToRemove])




  return (
    <article className={expertiseHolder}>
      <MotionConfig transition={{type:'tween'}}>
      <div className="lrHolder">
        <motion.div animate={lhsControl} variants={lhsVariants} className="lhs">
          <div className="inner">
            <RisingSpans staggerChildren={0.3} controls={headerControl} exitAnimation={false}><h1>Our areas of expertise</h1></RisingSpans>
            <motion.ul variants={lhsVariants} animate={lhsControl} initial="pre">
              <motion.li variants={liVariants} >Photovoltaic project development</motion.li>
              <motion.li variants={liVariants} ><Tick />Engineering, procurement, construction (EPC)</motion.li>
              <motion.li variants={liVariants} ><Tick />PV asset management</motion.li>
              <motion.li variants={liVariants} ><Tick />Private PPA and self consumption solutions</motion.li>
              <motion.li variants={liVariants} ><Tick />Utitlity-scale energy storage systems </motion.li>
            </motion.ul>

            <motion.div variants={liVariants} animate={lhsControl} initial="pre">
              <GreenButton to="/our-services" alt="Our Services">
                Our Services & Process
              </GreenButton>
            </motion.div>

            <svg className="dark" width="493" height="2" viewBox="0 0 493 2" fill="none" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
              <motion.path animate={lineControl} initial="pre" variants={lineVariants} d="M0.5 1H493" stroke="currentColor" stroke-width="2"/>
            </svg>
          </div>
        </motion.div>
        <motion.div animate={rhsControl} variants={rhsVariants} initial="pre" className="rhs">
          <StaticImage
            src="../../images/about/expertise.jpg"
            alt="Benefits of CCS"
            objectPosition="100% 100%"
            objectFit="contain"
            placeholder="blurred"
          />
        </motion.div>

      </div>


      <motion.div className="sun">
        <svg className="dark" width="407" height="407" viewBox="0 0 407 407" fill="none" xmlns="http://www.w3.org/2000/svg">
          <motion.path animate={sunControl} initial="pre" variants={sunVariants} d="M405.5 203.5C405.5 315.062 315.062 405.5 203.5 405.5C91.9385 405.5 1.5 315.062 1.5 203.5C1.5 91.9385 91.9385 1.5 203.5 1.5C315.062 1.5 405.5 91.9385 405.5 203.5Z" stroke="currentColor" stroke-width="3"/>
        </svg>
      </motion.div>

     </MotionConfig>
    </article>
  )
}
